import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { endpoints } from "global/endpoints";
import DataProvider from "context/DataContext";
import useOnClickOutside from "utils/outsideClick";
import useWindowSize from "utils/windowSize";
import { useLogout } from "hooks/auth/useLogout";
import { SidebarData } from "./data";
import SubMenu from "./SubMenu";
import ExtraHelpLinks from "./Extras/extraHelp";
import UpgradeNudge from "./UpgradeNudge";
import toast from "react-hot-toast";
import http from "utils/httpNew";
import { useLocation } from "react-router-dom";

export default function Sidebar() {
  const { theme, setOnSidebarClick, userData } = useContext(DataProvider);
  const [orgData, setOrgData] = useState({});
  const handleLogout = useLogout();

  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const size = useWindowSize();
  let sidebarRef = useRef(null);

  useOnClickOutside(sidebarRef, () => setOnSidebarClick(false));

  const itemProps = size.width < 1200 ? { ref: sidebarRef } : {};

  useEffect(() => {
    if (location) {
      setOnSidebarClick(false);
    }
  }, [location]);

  useEffect(() => {
    async function getOrgData() {
      try {
        const data = await http({}).getResponse(endpoints.org);
        setOrgData(data?.data);
      } catch (error) {
        toast.error(error?.error?.message);
      } finally {
        setLoading(false);
      }
    }
    getOrgData();
  }, []);

  if (loading || !userData) {
    // Ensure orgData is populated before rendering
    return null; // Or a loading spinner
  }

  const shouldShowUpgradeNudge = !(
    userData?.roles?.some((role) =>
      ["video-readonly", "developer"].includes(role)
    ) ||
    orgData?.plan_data?.video?.plan === "enterprise" ||
    orgData?.stripe_account_loc === "ind"
  );

  return (
    <React.Fragment>
      <aside
        {...itemProps}
        className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white navbar-white navbar-vertical-aside-initialized"
      >
        <div className="navbar-vertical-container">
          <div className="navbar-vertical-footer-offset">
            <a className="navbar-brand" href="/">
              {theme === "dark" ? (
                <img
                  className="navbar-brand-logo"
                  src="https://assets.gumlet.io/assets/gumlet-logo-white-font.png?w=240&format=auto"
                  alt="Logo"
                />
              ) : (
                <img
                  className="navbar-brand-logo"
                  src="https://assets.gumlet.io/assets/gumlet-logo-black-font.png?w=240&format=auto"
                  alt="Logo"
                />
              )}
              <img
                className="navbar-brand-logo-mini"
                src="https://assets.gumlet.io/assets/round-logo.png?w=100&format=auto"
                alt="Logo"
              />
            </a>
            <div
              className="navbar-vertical-content"
              style={{
                paddingBottom: "120px",
              }}
            >
              <div
                id="navbarVerticalMenu"
                className="nav nav-pills nav-vertical card-navbar-nav"
              >
                <Fragment>
                  {SidebarData.map((item, index) => {
                    return (
                      <SubMenu
                        item={item}
                        key={index}
                        theme={theme}
                        handleLogout={handleLogout}
                        userData={userData}
                      />
                    );
                  })}
                </Fragment>
              </div>
            </div>
            {/* navbar footer */}
            {shouldShowUpgradeNudge && (
              <UpgradeNudge orgData={orgData} theme={theme} />
            )}
            <div
              className="navbar-vertical-footer justify-content-start"
              style={{
                background: theme === "dark" ? "#191C24" : "#ffffff",
              }}
            >
              <ExtraHelpLinks />
            </div>
          </div>
        </div>
      </aside>
      <div className="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-mobile-overlay"></div>
    </React.Fragment>
  );
}
