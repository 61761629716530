import { format, startOfMonth } from "date-fns";
import http from "utils/httpNew";
import { endpoints } from "global/endpoints";
import { formatBytes1000 } from "utils/helper";

async function getAnalyticsData(endpoint) {
  const payload = {
    metrics: ["bandwidth_consumption"],
    group_by: "monthly",
    date_range: {
      start_at: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      end_at: format(new Date(), "yyyy-MM-dd"),
    },
  };

  try {
    const data = await http({}).post(endpoint, payload);
    const bandwidth_consume =
      data.bandwidth_consumption && data.bandwidth_consumption.length > 0
        ? data.bandwidth_consumption.reduce((prev, cur) => prev + cur.units, 0)
        : 0;
    const formattedBandwidth = formatBytes1000(bandwidth_consume, 1);
    return formattedBandwidth;
  } catch (error) {
    throw error;
  }
}

export async function getImageAnalyticsData() {
  return getAnalyticsData(endpoints.image.analytics);
}

export async function getVideoAnalyticsData() {
  return getAnalyticsData(endpoints.video.analytics);
}
