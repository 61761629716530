import React, { useState, useEffect, Fragment, useRef } from "react";
import DataContext from "./DataContext";
import moment from "moment";
import useLocalStorage from "utils/useLocalStorage";
import http from "utils/httpNew";
import { endpoints } from "global/endpoints";
import Cookies from "js-cookie";
import Modal from "react-responsive-modal";
import PricingModal from "components/PricingModal/PricingModal";
import ProcessVideo from "components/ProcessVideo";
import { hotjar } from "react-hotjar";
import { useIntercom } from "react-use-intercom";
import { useLocation, useSearchParams } from "react-router-dom";
import FeedbackModal from "../components/PricingModal/FeedbackModal";
import { routePaths } from "../global/routePaths";

// global state management
export const DataProvider = ({ children }) => {
  const { boot, shutdown, hide, show, update } = useIntercom();
  const [theme, setTheme] = useState("");
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isProfile, selectedProfile] = useState("");
  const [isSwitchedUser, setSwitchedUser] = useState(false);
  const [drmPopup, setdrmPopup] = useState(false);
  const [enableDRM, setEnableDRM] = useState(false);
  const [isUploadBtnClick, setIsUploadBtnClick] = useState(false);
  const [checked, setChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [feedbackModal, setFeedbackModal] = useState(false);

  const location = useLocation();

  var user_plan = Cookies.get("plan");
  var intercom_var = searchParams.get("open_plans_modal");
  const showUploadModal = searchParams.get("upload");

  const [allPlaylistAssets, setAllPlaylistAssets] = useState({
    all_assets: [],
    distinct_tags: [],
    total_asset_count: 0,
  });

  const [toggleInvite, setToggleInvite] = useState(false);

  const [vimeokey, setVimeoKey] = useState(false);
  const [wistiakey, setWistiaKey] = useState(false);
  const [youtubeLinked, setYoutubeLinked] = useState(false);
  const [zoomLinked, setZoomLinked] = useState(false);
  // Get the current URL
  const url = new URL(window.location.href);
  // Get the 'source' query parameter from the URL

  const [videoFormdata, setVideoFormData] = useState({});

  const [openPriceModal, setOpenPriceModal] = useState(false);

  const onOpenPriceModal = () => {
    setOpenPriceModal(true);
  };

  const onClosePriceModal = () => {
    setOpenPriceModal(false);

    if (searchParams.has("open_plans_modal")) {
      searchParams.delete("open_plans_modal");
      setSearchParams(searchParams);
    }
  };

  const [openUppy, setOpenUppy] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const setFormValues = (values) => {
    setVideoFormData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await http({}).get(endpoints.user);
        setUserData(data);
        updateUserData(data);

        if (data?.email === "demo@gumlet.com") {
          setChecked(true);
        }
        if (data?.vimeo) {
          setVimeoKey(data?.vimeo?.linked);
        }

        if (data?.youtube?.linked) {
          setYoutubeLinked(data?.youtube?.linked);
        }

        if (data?.wistia) {
          setWistiaKey(data?.wistia?.linked);
        }

        if (data?.zoom) {
          setZoomLinked(data?.zoom?.linked);
        }

        if (showUploadModal && window.location.pathname === "/video/manage") {
          setOpenUppy(true);
          setBackdrop(true);
          setIsUploadBtnClick(true);
        }

        // open price modal if cookie have plan variable
        if (data?.signupWithPlan || user_plan || intercom_var) {
          onOpenPriceModal();
        }

        var switchedUser = Cookies.get("switched_user");
        if (switchedUser) {
          setSwitchedUser(true);
        }

        if (typeof switchedUser === "undefined") {
          window.Canny("identify", {
            appID: "60ccff5c3e037e7772bc6b68",
            user: {
              // Replace these values with the current user's data
              email: data.email,
              name: data.name,
              id: data.id,
              created: new Date(data.creation_date).toISOString(),
            },
          });
          boot({
            email: data.email,
            createdAt: parseInt(data.creation_date / 1000),
            name: data.name,
            userId: data.id,
            userHash: data.intercom_hash,
            plan: data.plans,
            roles: data.roles?.join(","),
            // customAttributes: {
            //   plan: data.plans,
            //   roles: data.roles?.join(","),
            // },
          });
          // only load hotjar for non-localhost pages
          if (
            typeof window !== "undefined" &&
            window.location.hostname !== "localhost"
          ) {
            hotjar.initialize({ id: "1660179", sv: 6 });
            hotjar.identify(data.id, {
              email: data.email,
              plans: data.plans,
              createdAt: new Date(data.creation_date).toISOString(),
            });
          }
        } else {
          setSwitchedUser(true);
          // don't load segment if user is switched...
        }
      } catch (error) {
        // toast.error(error.error.message);
      }
    };

    if (location.pathname !== routePaths.publicVideoUpload) {
      getUserData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserData = async (data) => {
    // check if user.metadata.send_ga_signup_event is false then send the GA event
    if (!data?.metadata?.send_ga_signup_event) {
      // send ga event
      window.gtag("event", "sign_up");
      // post call to update the metadata
      const updated_data = await http({}).post(endpoints.user, {
        metadata: {
          send_ga_signup_event: true, // set the value to true
        },
      });
      setUserData(updated_data);
    }
  };

  //property id value
  const [selectedProperty] = useLocalStorage("property");
  const [property, setProperty] = useState(
    selectedProperty ? selectedProperty : ""
  );

  const [onSidebarClick, setOnSidebarClick] = useState(false);

  // date range
  const [state, setState] = useState({
    start: moment().subtract(6, "days"),
    end: moment(),
  });

  //chart data array
  const [chartData, setChartData] = useState([]);

  //heatchart data array
  const [heatData, setHeatData] = useState([]);

  //filters array
  const [addFiltersToStorage] = useLocalStorage("filters");
  const [selectedFilter, setSelectedFilter] = useState(
    addFiltersToStorage ? addFiltersToStorage : []
  );

  const [viewsData, setViewsData] = useState([]);

  //demo mode toggle
  // const [addDemoStorage] = useLocalStorage("demo");

  const [checkBreakDownChart, setCheckBreakDownChart] = useState(false);

  /////////////////// reports data /////////////////////////
  //report event dropdown toggle
  const [reportEventValue, setReportEventValue] = useState("");
  //report breakdown dropdown toggle
  const [reportBreakDownValue, setReportBreakDownValue] = useState(null);
  //report data counts
  const [reportCount, setReportCount] = useState(0);
  //report chart data
  const [reportChartData, setReportChartData] = useState([]);
  //report breakdown data
  const [reportBreakDownData, setReportBreakDownData] = useState({
    data: [],
    has_next_page: false,
  });
  //report loading state
  const [reportLoading, setReportLoading] = useState(false);

  // total dashboard data
  const [dashboardData, setDashboadData] = useState([]);

  // get id
  const [currentDashboardJson, setCurrentDashboardJson] = useState({
    id: "",
    title: "",
    description: "",
    user_aggregation_by: "",
  });

  //report breakdown filter data for chart
  const [reportBreakdownFilterData, setReportBreakDownFilterData] = useState(
    []
  );

  const [reportRequestPayload, setReportRequestPayload] = useState({
    aggregate: [],
    filters: [],
    group_by: "daily",
  });

  const [lastRequestSHA, setLastRequestSHA] = useState("");

  // date range for report
  const [reportdate, setReportDate] = useState([
    moment().subtract(7, "days"),
    moment(),
  ]);

  return (
    <Fragment>
      <DataContext.Provider
        value={{
          property,
          setProperty,
          setSelectedFilter,
          selectedFilter,
          setState,
          state,
          chartData,
          setChartData,
          setViewsData,
          viewsData,
          setLoading,
          loading,
          setChecked,
          checked,
          setHeatData,
          heatData,
          setCheckBreakDownChart,
          checkBreakDownChart,
          setReportChartData,
          reportChartData,
          setReportEventValue,
          reportEventValue,
          setReportLoading,
          reportLoading,
          setReportCount,
          reportCount,
          reportBreakDownData,
          setReportBreakDownData,
          reportBreakDownValue,
          setReportBreakDownValue,
          setDashboadData,
          dashboardData,
          setCurrentDashboardJson,
          currentDashboardJson,
          setReportBreakDownFilterData,
          reportBreakdownFilterData,
          reportRequestPayload,
          setReportRequestPayload,
          lastRequestSHA,
          setLastRequestSHA,
          setTheme,
          theme,
          reportdate,
          setReportDate,
          onSidebarClick,
          setOnSidebarClick,
          userData,
          setUserData,
          videoFormdata,
          setFormValues,
          isProfile,
          selectedProfile,
          setOpenUppy,
          setAllPlaylistAssets,
          allPlaylistAssets,
          openUppy,
          setdrmPopup,
          drmPopup,
          enableDRM,
          setEnableDRM,
          vimeokey,
          setVimeoKey,
          youtubeLinked,
          setYoutubeLinked,
          backdrop,
          setBackdrop,
          isUploadBtnClick,
          setIsUploadBtnClick,
          wistiakey,
          setWistiaKey,
          setFeedbackModal,
          setZoomLinked,
          zoomLinked,
          setToggleInvite,
          toggleInvite,
        }}
      >
        {children}
      </DataContext.Provider>

      {user_plan || userData?.signupWithPlan || intercom_var ? (
        <Modal
          open={openPriceModal}
          onClose={onClosePriceModal}
          center
          showCloseIcon={false}
          classNames={{
            modal: "newPriceModal",
          }}
        >
          <PricingModal
            onCloseModal={onClosePriceModal}
            userData={userData}
            theme={theme}
          />
        </Modal>
      ) : (
        <></>
      )}

      {openUppy ? (
        <ProcessVideo
          setOpenUppy={setOpenUppy}
          theme={theme}
          setAllPlaylistAssets={setAllPlaylistAssets}
          allPlaylistAssets={allPlaylistAssets}
          setBackdrop={setBackdrop}
          setIsUploadBtnClick={setIsUploadBtnClick}
          setSearchParams={setSearchParams}
        />
      ) : (
        <></>
      )}

      <Modal
        open={feedbackModal}
        onClose={setFeedbackModal}
        showCloseIcon={false}
      >
        <FeedbackModal
          setFeedbackModal={setFeedbackModal}
          theme={theme}
          feedbackModal={feedbackModal}
          userData={userData}
        />
      </Modal>
    </Fragment>
  );
};
